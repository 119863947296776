<template>
  <div class="container-fluid">
    <loading
      :active.sync="isLoading"
      :color="'#408521'"
      :is-full-page="false"
    ></loading>

    <article class="post-area single-blog">
      <b-row>
        <b-col md="12">
          <h2>Uporabniki</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="my-5">
          <download-excel
            :data="users"
            class="btn btn-primary text-white float-right mb-2"
          >
            <i class="fa fa-file-excel"></i> Prenesi podatke
          </download-excel>
          <div class="clearfix"></div>

          <vue-good-table
            v-if="users"
            :columns="columns"
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'createdAt.seconds', type: 'desc'}
            }"
            styleClass="tableOne vgt-table striped"
            :rows="users"
            :search-options="{
              enabled: true,
              skipDiacritics: true,
              placeholder: 'vnesi iskalni pojem',
              searchFn: searchFunc
            }"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'id'">
                {{ props.index + 1 }}
              </div>
              <div v-if="props.column.field == 'firstName'">
                {{ props.row.firstName }}
              </div>
              <div v-if="props.column.field == 'lastName'">
                {{ props.row.lastName }}
              </div>
              <div v-if="props.column.field == 'displayName'">
                {{ props.row.displayName }}
              </div>
              <div v-if="props.column.field == 'email'">
                {{ props.row.email }}
              </div>
              <div v-if="props.column.field == 'phone'">
                {{ props.row.phone }}
              </div>
              <div v-if="props.column.field == 'lastLoginAt.seconds'">
                {{
                  props.row.lastLoginAt
                    ? moment
                        .unix(props.row.lastLoginAt.seconds)
                        .format("DD. MM. YYYY, HH:mm")
                    : "n/a"
                }}
              </div>
              <div v-if="props.column.field == 'createdAt.seconds'">
                {{
                  moment
                    .unix(props.row.createdAt.seconds)
                    .format("DD. MM. YYYY, HH:mm")
                }}
              </div>
              <div v-if="props.column.field == 'actions'">
                <a
                  class="btn btn-sm btn-secondary text-white text-uppercase mx-1"
                  @click.stop="showUsersDetails(props.row)"
                  ><i class="fa fa-info"></i> Podrobno</a
                >
                <!--              <a class="btn btn-sm btn-info text-white text-uppercase mx-1" @click.stop="editUnit(props.row.id)"><i-->
                <!--                  class="fa fa-edit"></i> Edit</a>-->
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>

      <b-modal
        ref="user-details"
        id="user-details"
        title="Podrobnosti uporabnika"
      >
        <b-row v-if="selectedUser">
          <loading
            :active.sync="isLoadingInModal"
            :color="'#408521'"
            :is-full-page="false"
          ></loading>

          <b-col lg="12" md="12" sm="12">
            <vue-json-to-table
              :data="selectedUser"
              class="overflow-hidden text-truncate"
            ></vue-json-to-table>
          </b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <div class="float-left">
              <!--            <a class="btn btn-danger text-white mr-3" @click.stop="beforeDeleteUnit()">-->
              <!--              <i class="fa fa-trash"></i> Delete unit-->
              <!--            </a>-->
              <!--            <b-button-->
              <!--                variant="info"-->
              <!--                @click="() => { $router.push({name: 'EditUnit', params: {id: selectedUnit.id}})}">-->
              <!--              <i class="fa fa-edit"></i> Edit-->
              <!--            </b-button>-->
            </div>
            <b-button
              variant="secondary"
              class="float-right"
              @click="
                () => {
                  $refs['user-details'].hide();
                }
              "
            >
              Zapri
            </b-button>
          </div>
        </template>
      </b-modal>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
import { db } from "../../main";
import Loading from "vue-loading-overlay";
import JsonExcel from "vue-json-excel";
// import _ from "lodash";

export default {
  name: "Users",
  components: {
    Loading,
    downloadExcel: JsonExcel
  },
  data() {
    return {
      users: null,
      columns: [
        {
          label: "#",
          field: "id",
          width: "20px",
          sortable: true,
        },
        {
          label: "Ime",
          field: "firstName",
          sortable: true,
        },
        {
          label: "Priimek",
          field: "lastName",
          sortable: true,
        },
        {
          label: "Prikazno ime",
          field: "displayName",
          sortable: true,
        },
        {
          label: "Email",
          field: "email",
          sortable: false,
        },
        {
          label: "Telefon",
          field: "phone",
          sortable: false,
        },
        {
          label: "Zadnja prijava",
          field: "lastLoginAt.seconds",
          sortable: true,
        },
        {
          label: "Registracija",
          field: "createdAt.seconds",
          sortable: true,
        },
        {
          label: "Actions",
          field: "actions",
          width: "250px",
          sortable: false,
        }
      ],
      selectedUser: null,
      isLoadingInModal: false,
      isLoading: false
    };
  },
  mounted() {
    console.log("Users");
    this.retrieveUsers();
  },
  methods: {
    async retrieveUsers() {
      const _this = this;

      _this.isLoading = true;

      let query = db.collection("users");

      await query
        .get()
        .then(function(_docs) {
          console.log("here", _docs);
          _this.users = _docs.docs.map(doc => doc.data());

          setTimeout(() => {
            _this.isLoading = false;
          }, 200);
        })
        .catch(error => {
          console.log("Error getting users:", error);
          _this.isLoading = false;
        });
    },

    showUsersDetails(user) {
      const _this = this;

      _this.selectedUser = user;
      _this.$refs["user-details"].show();
    },

    updateUser(data, recordId) {
      const _this = this;

      console.log("recordId", recordId);

      db.collection("users")
        .doc(recordId)
        .update({
          id: data.user.id,
          displayName: data.user.displayName,
          email: data.user.email,
          lastLogin: new Date()
        })
        .then(function() {
          console.log("Record updated");
          _this.$router.replace({ name: "About" });
        })
        .catch(function(error) {
          console.error("Error adding Record: ", error);
        });
    },

    addUser(data) {
      const _this = this;

      db.collection("users")
        .add({
          id: data.user.uid,
          displayName: data.user.displayName,
          email: data.user.email,
          lastLogin: new Date(),
          created: new Date()
        })
        .then(function(docRef) {
          console.log("Record written with ID: ", docRef.id);
          _this.$router.replace({ name: "About" });
        })
        .catch(function(error) {
          console.error("Error adding Record: ", error);
        });
    },

    searchFunc(row, col, cellValue, searchTerm) {
      const _this = this;
      if (
        _this.searchFoUser(_this.$globalFunctions.getUserById(row), searchTerm)
      ) {
        return true;
      }
    },

    searchFoUser(user, query) {
      return (
        JSON.stringify(user)
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    }
  }
};
</script>
