<template>
  <b-container>
    <loading
        :active.sync="isLoading"
        :color="'#408521'"
        :is-full-page="false"
    ></loading>

    <article class="post-area single-blog">
      <b-row>
        <b-col md="12">
          <h2>Potrjeni zapisi</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div v-if="posts && posts.length === 0" class="my-5">
            Trenutno ni potrjenih zapisov.
          </div>

          <div v-if="posts && posts.length > 0" class="my-5">
            <vue-good-table
                :columns="columns"
                :search-options="{
                enabled: true,
                skipDiacritics: true,
                placeholder: 'vnesi iskalni pojem',
                searchFn: searchFunc
              }"
                styleClass="tableOne vgt-table striped"
                :rows="posts"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'post'">
                  <h3 class="mt-0 mb-0">[{{ props.index + 1 }}] {{ props.row.title }}</h3>
                  <p class="mt-0 mb-0">{{ props.row.summary }}</p>
                  <p class="mt-0 mb-0">
                    <b class="pr-2">Avtor:</b>
                    <span v-if="props.row.createdBy">{{
                        $globalFunctions.getUserById(props.row.createdBy)
                            .displayName
                      }}</span>
                  </p>
                  <p class="mt-0 mb-0">
                    <b class="pr-2">Status:</b>
                    <span v-if="!props.row.submitted">začasno shranjen</span>
                    <span v-if="props.row.submitted && !props.row.confirmed"
                    >oddan v preverjanje</span
                    >
                    <span v-if="props.row.confirmed">potrjen in objavljen</span>
                  </p>
                  <p class="mt-0 mb-0">
                    <a
                        class="btn btn-sm btn-secondary text-white text-uppercase mx-1"
                        @click.stop="showPost(props.row.id)"
                    ><i class="fa fa-eye"></i> Poglej</a
                    >
                    <a
                        class="btn btn-sm btn-info text-white text-uppercase mx-1"
                        @click.stop="editPost(props.row.id)"
                    ><i class="fa fa-edit"></i> Preglej in uredi</a
                    >
                    <a
                        class="btn btn-sm btn-warning text-uppercase mx-1"
                        v-if="!props.row.en_title || !props.row.en_summary"
                        @click.stop="translateAndUpdatePost(props.row)"
                    ><i class="fa fa-flag"></i> Dodaj angleške prevode</a
                    >
                  </p>
                </div>
                <div v-if="props.column.field == 'summary'">
                  {{ props.row.summary }}
                </div>
                <div v-if="props.column.field == 'status'">
                  <span v-if="!props.row.submitted">začasno shranjen</span>
                  <span v-if="props.row.submitted && !props.row.confirmed"
                  >oddan v preverjanje</span
                  >
                  <span v-if="props.row.confirmed">potrjen in objavljen</span>
                </div>
                <div v-if="props.column.field == 'actions'">
                  <a
                      class="btn btn-sm btn-secondary text-white text-uppercase mx-1"
                      @click.stop="showPost(props.row.id)"
                  ><i class="fa fa-eye"></i> Poglej</a
                  >
                  <a
                      class="btn btn-sm btn-info text-white text-uppercase mx-1"
                      @click.stop="editPost(props.row.id)"
                  ><i class="fa fa-edit"></i> Uredi</a
                  >
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-col>
      </b-row>
    </article>
  </b-container>
</template>

<script>
import Vue from "vue";
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";
import Loading from "vue-loading-overlay";
import {db} from "@/main";
// import _ from "lodash";
// import _ from "lodash";

export default {
  name: "MyPosts",
  components: {
    Loading
  },
  data() {
    return {
      posts: null,
      columns: [
        {
          label: "Zapis",
          field: "post"
        }
      ],
      isLoading: false
    };
  },
  computed: {
    // filteredPosts() {
    //   const _this = this;
    //   //.where("submitted", "==", _this.viewFilter === "temp" ? false : true)
    //   return _.filter(_this.posts, {
    //     submitted: _this.viewFilter === "temp" ? false : true
    //   });
    // }
  },
  mounted() {
    const _this = this;

    let p1 = _this.$store.dispatch("setUsers");

    Promise.all([p1]).then(() => {
      _this.retrievePosts();
    });
  },
  methods: {
    retrievePosts() {
      const _this = this;

      _this.isLoading = true;

      _this.post = db
          .collection("posts")
          .where("confirmed", "==", true)
          .get()
          .then(function (_docs) {
            _this.posts = _docs.docs.map(doc => doc.data());
            _this.isLoading = false;
          })
          .catch(error => {
            console.log("Error getting categories:", error);
            _this.isLoading = false;
          });
    },

    showPost(postItem) {
      const _this = this;
      _this.$router.push({
        name: "PreviewPost",
        params: {postId: postItem}
      });
    },

    editPost(postItem) {
      const _this = this;
      _this.$router.push({name: "EditPost", params: {postId: postItem}});
    },

    async translateAndUpdatePost(postItem) {
      const _this = this;
      console.log("postItem", postItem);

      _this.isLoading = true;

      let en_title = await _this.translateString(postItem.title, 'SL', 'en-US');
      let en_summary = await _this.translateString(postItem.summary, 'SL', 'en-US');
      let en_content = postItem.content ? await _this.translateString(postItem.content, 'SL', 'en-US') : null;
      console.log("en_title: ", en_title, "; en_summary: ", en_summary, "; en_content: ", en_content);

      if (!en_title || !en_summary || typeof en_content === 'undefined') {
        _this.isLoading = false;
        return false;
      }

      const post = db.collection("posts").doc(postItem.id);

      return post
          .set({
            en_title: en_title,
            en_summary: en_summary,
            en_content: en_content,
            modifiedAt: new Date(),
          }, {merge: true})
          .then(function () {
            _this.retrievePosts();
            _this.$swal("Vsebina uspešno prevedena in shranjena 👍", "", "success");
            _this.isLoading = false;
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating user: ", error);
            _this.isLoading = false;
          });

    },

    // async translateString(stringToTranslate) {
    //   console.log("stringToTranslate", stringToTranslate);
    //
    //   const keys = ['9afd53bb-2099-cfa9-4af5-d710413701bd:fx']
    //   // prod api: https://api.deepl.com/v2/translate
    //   // prod key: 'DeepL-Auth-Key e3bb8e9f-5344-9d6f-1eae-2dc5089acd90'
    //   try {
    //     let translation = await Vue.axios.get('https://api-free.deepl.com/v2/translate', {
    //       params: {text: stringToTranslate, target_lang: 'EN'},
    //       headers: {
    //         Authorization: 'DeepL-Auth-Key ' + keys[Math.floor(Math.random() * keys.length)],
    //       },
    //       proxy: {
    //         host: "localhost",
    //         port: 8080
    //       }
    //     });
    //     console.log("translation", translation.data.translations[0].text);
    //     return translation.data.translations && translation.data.translations[0].text ? translation.data.translations[0].text : stringToTranslate
    //   } catch (e) {
    //     console.log(e);
    //     this.$swal("Pri prevajanju je prišlo do napake 😭", e.message, "error");
    //   }
    // },

    async translateString(stringToTranslate, sourceLang, targetLang) {

      try {
        let translation = await Vue.axios.post("https://us-central1-zapisispomina.cloudfunctions.net/translate", {text: stringToTranslate, sourceLang: sourceLang, targetLang: targetLang}, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          proxy: {
            host: "localhost",
            port: 8081
          }
        });
        console.log("translation", translation);
        return translation.data
      } catch (e) {
        console.log(e);
        this.$swal("Pri prevajanju je prišlo do napake 😭", e.message, "error");
      }
    },

    searchFunc(row, col, cellValue, searchTerm) {
      const _this = this;
      if (
          row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.summary.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (row.content
              ? row.content.toLowerCase().includes(searchTerm.toLowerCase())
              : false) ||
          _this.searchFoUser(
              _this.$globalFunctions.getUserById(row.createdBy),
              searchTerm
          )
      ) {
        return true;
      }
    },

    searchFoUser(user, query) {
      return (
          JSON.stringify(user)
              .toLowerCase()
              .indexOf(query.toLowerCase()) > -1
      );
    }
  }
};
</script>
