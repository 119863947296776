<template>
  <b-container>
    <loading
      :active.sync="isLoading"
      :color="'#408521'"
      :is-full-page="false"
    ></loading>

    <article class="post-area single-blog">
      <b-row>
        <b-col md="12">
          <h2>Izbrisani zapisi</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div v-if="posts && posts.length === 0" class="my-5">
            Trenutno ni izbrisanih zapisov.
          </div>

          <div v-if="posts && posts.length > 0" class="my-5">
            <vue-good-table
              :columns="columns"
              :search-options="{
                enabled: true,
                skipDiacritics: true,
                placeholder: 'vnesi iskalni pojem',
                searchFn: searchFunc
              }"
              styleClass="tableOne vgt-table striped"
              :rows="posts"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'post'">
                  <h3 class="mt-0 mb-0">{{ props.row.title }}</h3>
                  <p class="mt-0 mb-0">{{ props.row.summary }}</p>
                  <p class="mt-0 mb-0">
                    <b class="pr-2">Avtor:</b>
                    <span v-if="props.row.createdBy">{{
                      $globalFunctions.getUserById(props.row.createdBy)
                        .displayName
                    }}</span>
                  </p>
                  <p class="mt-0 mb-0">
                    <b class="pr-2">Status:</b>
                    <span v-if="!props.row.submitted">začasno shranjen</span>
                    <span v-if="props.row.submitted && !props.row.confirmed"
                      >oddan v preverjanje</span
                    >
                    <span v-if="props.row.confirmed">potrjen in objavljen</span>
                  </p>
                  <p class="mt-0 mb-0">
                    <a
                      class="btn btn-sm btn-secondary text-white text-uppercase mx-1"
                      @click.stop="showPost(props.row.id)"
                      ><i class="fa fa-eye"></i> Poglej</a
                    >
                    <a
                      class="btn btn-sm btn-info text-white text-uppercase mx-1"
                      @click.stop="editPost(props.row.id)"
                      ><i class="fa fa-edit"></i> Preglej in uredi</a
                    >
                  </p>
                </div>
                <div v-if="props.column.field == 'summary'">
                  {{ props.row.summary }}
                </div>
                <div v-if="props.column.field == 'status'">
                  <span v-if="!props.row.submitted">začasno shranjen</span>
                  <span v-if="props.row.submitted && !props.row.confirmed"
                    >oddan v preverjanje</span
                  >
                  <span v-if="props.row.confirmed">potrjen in objavljen</span>
                </div>
                <div v-if="props.column.field == 'actions'">
                  <a
                    class="btn btn-sm btn-secondary text-white text-uppercase mx-1"
                    @click.stop="showPost(props.row.id)"
                    ><i class="fa fa-eye"></i> Poglej</a
                  >
                  <a
                    class="btn btn-sm btn-info text-white text-uppercase mx-1"
                    @click.stop="editPost(props.row.id)"
                    ><i class="fa fa-edit"></i> Uredi</a
                  >
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-col>
      </b-row>
    </article>
  </b-container>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";
import Loading from "vue-loading-overlay";
import { db } from "@/main";
// import _ from "lodash";

export default {
  name: "MyPosts",
  components: {
    Loading
  },
  data() {
    return {
      posts: null,
      columns: [
        {
          label: "Zapis",
          field: "post",
          searchable: true
        }
      ],
      isLoading: false
    };
  },
  computed: {
    // filteredPosts() {
    //   const _this = this;
    //   //.where("submitted", "==", _this.viewFilter === "temp" ? false : true)
    //   return _.filter(_this.posts, {
    //     submitted: _this.viewFilter === "temp" ? false : true
    //   });
    // }
  },
  mounted() {
    const _this = this;

    let p1 = _this.$store.dispatch("setUsers");

    Promise.all([p1]).then(() => {
      _this.retrievePosts();
    });
  },
  methods: {
    retrievePosts() {
      const _this = this;

      _this.isLoading = true;

      _this.post = db
        .collection("posts")
        .where("deleted", "==", true)
        .get()
        .then(function(_docs) {
          _this.posts = _docs.docs.map(doc => doc.data());
          _this.isLoading = false;
        })
        .catch(error => {
          console.log("Error getting categories:", error);
          _this.isLoading = false;
        });
    },

    showPost(postItem) {
      const _this = this;
      _this.$router.push({
        name: "PreviewPost",
        params: { postId: postItem }
      });
    },

    editPost(postItem) {
      const _this = this;
      _this.$router.push({ name: "EditPost", params: { postId: postItem } });
    },

    searchFunc(row, col, cellValue, searchTerm) {
      const _this = this;
      if (
        row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.summary.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.content
          ? row.content.toLowerCase().includes(searchTerm.toLowerCase())
          : false) ||
        _this.searchFoUser(
          _this.$globalFunctions.getUserById(row.createdBy),
          searchTerm
        )
      ) {
        return true;
      }
    },

    searchFoUser(user, query) {
      return (
        JSON.stringify(user)
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    }
  }
};
</script>
