var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('loading',{attrs:{"active":_vm.isLoading,"color":'#408521',"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('article',{staticClass:"post-area single-blog"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h2',[_vm._v("Uporabniki")])])],1),_c('b-row',[_c('b-col',{staticClass:"my-5",attrs:{"md":"12"}},[_c('download-excel',{staticClass:"btn btn-primary text-white float-right mb-2",attrs:{"data":_vm.users}},[_c('i',{staticClass:"fa fa-file-excel"}),_vm._v(" Prenesi podatke ")]),_c('div',{staticClass:"clearfix"}),(_vm.users)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"sort-options":{
            enabled: true,
            initialSortBy: {field: 'createdAt.seconds', type: 'desc'}
          },"styleClass":"tableOne vgt-table striped","rows":_vm.users,"search-options":{
            enabled: true,
            skipDiacritics: true,
            placeholder: 'vnesi iskalni pojem',
            searchFn: _vm.searchFunc
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'id')?_c('div',[_vm._v(" "+_vm._s(props.index + 1)+" ")]):_vm._e(),(props.column.field == 'firstName')?_c('div',[_vm._v(" "+_vm._s(props.row.firstName)+" ")]):_vm._e(),(props.column.field == 'lastName')?_c('div',[_vm._v(" "+_vm._s(props.row.lastName)+" ")]):_vm._e(),(props.column.field == 'displayName')?_c('div',[_vm._v(" "+_vm._s(props.row.displayName)+" ")]):_vm._e(),(props.column.field == 'email')?_c('div',[_vm._v(" "+_vm._s(props.row.email)+" ")]):_vm._e(),(props.column.field == 'phone')?_c('div',[_vm._v(" "+_vm._s(props.row.phone)+" ")]):_vm._e(),(props.column.field == 'lastLoginAt.seconds')?_c('div',[_vm._v(" "+_vm._s(props.row.lastLoginAt ? _vm.moment .unix(props.row.lastLoginAt.seconds) .format("DD. MM. YYYY, HH:mm") : "n/a")+" ")]):_vm._e(),(props.column.field == 'createdAt.seconds')?_c('div',[_vm._v(" "+_vm._s(_vm.moment .unix(props.row.createdAt.seconds) .format("DD. MM. YYYY, HH:mm"))+" ")]):_vm._e(),(props.column.field == 'actions')?_c('div',[_c('a',{staticClass:"btn btn-sm btn-secondary text-white text-uppercase mx-1",on:{"click":function($event){$event.stopPropagation();return _vm.showUsersDetails(props.row)}}},[_c('i',{staticClass:"fa fa-info"}),_vm._v(" Podrobno")])]):_vm._e()]}}],null,false,3583487158)}):_vm._e()],1)],1),_c('b-modal',{ref:"user-details",attrs:{"id":"user-details","title":"Podrobnosti uporabnika"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"float-left"}),_c('b-button',{staticClass:"float-right",attrs:{"variant":"secondary"},on:{"click":function () {
                _vm.$refs['user-details'].hide();
              }}},[_vm._v(" Zapri ")])],1)]},proxy:true}])},[(_vm.selectedUser)?_c('b-row',[_c('loading',{attrs:{"active":_vm.isLoadingInModal,"color":'#408521',"is-full-page":false},on:{"update:active":function($event){_vm.isLoadingInModal=$event}}}),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('vue-json-to-table',{staticClass:"overflow-hidden text-truncate",attrs:{"data":_vm.selectedUser}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }